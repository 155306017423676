

export const moduleConfig = {
  plugins: {},

  mixin: {
    async created() {
      
    },

    async beforeUnmount() {
      
    }
  }
}
